import NumberFormat from 'react-number-format';
import React, { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

export default function NumberInput(props) {
  const classes = useStyles();
  const InputComponent = useMemo(() => numberFormatCustom({
    suffix: props.suffix ?? ' €',
  }), [props.suffix]);

  return (
    <TextField
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
        className: clsx(props.InputProps?.className, {
          [classes.multiline]: Boolean(props.multiline)
        }),
        inputComponent: InputComponent
      }}
      {...props}
    />
  );
}

NumberInput.propTypes = {
  ...TextField.propTypes
};
NumberInput.defaultProps = {
  ...TextField.defaultProps,
};

// eslint-disable-next-line react/display-name
const numberFormatCustom = ({ suffix }) => (props) => {
  const { inputRef, onChange, allowNegative, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      allowNegative={allowNegative}
      suffix={suffix}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  multiline: {
    paddingTop: 7
  }
}));

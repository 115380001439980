import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
  },
  submitButton: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 300,
    }
  }
}));

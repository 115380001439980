import { FiArrowDown } from 'react-icons/fi';
import { Select as MuiSelect } from '@material-ui/core';
import React from 'react';

export default function Select(props) {
  return (
    <MuiSelect
      IconComponent={FiArrowDown}
      { ...props }
    />
  );
}

Select.propTypes = {
  ...MuiSelect.propTypes
};
Select.defaultProps = {
  ...MuiSelect.defaultProps,
};

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
} from '@material-ui/core';

import FORM_INITIAL_VALUES from './initialValues';
import { Formik } from 'formik';
import NumberInput from '@components/atoms/NumberInput';
import PropTypes from 'prop-types';
import Select from '@components/atoms/Select';
import { formikInjectedPropsTypes } from '@forms/propTypes';
import { useStyles } from './styles';
import validationSchema from './validate';

export default function InterestCalculator(props) {
  const {
    initialValues,
    onSubmit
  } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues ?? {
        ...FORM_INITIAL_VALUES
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikBag) => (
        <Form
          classes={classes}
          {...formikBag}
        />
      )}
    </Formik>
  );
}

InterestCalculator.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};

function Form(props) {
  const {
    classes,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    dirty,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl
            variant="outlined"
            fullWidth
            error={errors.financingReason && touched.financingReason}
          >
            <InputLabel id="form-financingReason">
              Finanzierungsgrund
            </InputLabel>
            <Select
              labelId="form-financingReason"
              value={values.financingReason}
              onChange={(e) => setFieldValue('financingReason', e.target.value)}
            >
              {FINANCING_REASONS.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            variant="outlined"
            fullWidth
            error={errors.fixedInterestRate && touched.fixedInterestRate}
          >
            <InputLabel id="form-fixedInterestRate">
              Zinsbindung
            </InputLabel>
            <Select
              labelId="form-fixedInterestRate"
              value={values.fixedInterestRate}
              onChange={(e) => setFieldValue('fixedInterestRate', e.target.value)}
            >
              {FIXED_INTEREST_RATES.map(item => (
                <MenuItem key={item} value={item}>
                  {`${item} Jahre`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberInput
            fullWidth
            name="purchasePrice"
            label="Kaufpreis"
            value={values.purchasePrice}
            error={errors.purchasePrice && touched.purchasePrice}
            helperText={errors.purchasePrice}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberInput
            fullWidth
            name="equity"
            label="Eigenkapital"
            value={values.equity}
            error={errors.equity && touched.equity}
            helperText={errors.equity}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Button
              fullWidth
              disabled={isSubmitting}
              size="large"
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={handleSubmit}
            >
              Zins berechnen
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

Form.propTypes = {
  ...formikInjectedPropsTypes
};

export const FINANCING_REASONS = [
  {
    name: 'Kauf eines Neubaus vom Bauträger',
    value: 'PurchaseFinancingBuild'
  },
  {
    name: 'Kauf einer bestehenden Immobilie',
    value: 'PurchaseFinancingExisting'
  },
  {
    name: 'Eigenes Bauvorhaben',
    value: 'BuildingFinancing'
  },
  {
    name: 'Anschlussfinanzierung / Umschuldung',
    value: 'FollowupFinancing'
  }
];

export const FIXED_INTEREST_RATES = [
  5,
  10,
  15,
  20
];

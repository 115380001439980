import { useState } from 'react';

export const FormState = {
  INITIAL: 'initial',
  SUCCESS: 'success',
  ERROR: 'error'
};

export default function useFormState() {
  const [formState, setFormState] = useState(FormState.INITIAL);
  const [formError, setFormError] = useState(null);

  return {
    formState,
    formError,
    handleGraphQlResult: (result) => {
      if (result.status === 'success') {
        setFormState(FormState.SUCCESS);
      } else {
        setFormState(FormState.ERROR);
      }

      setFormError(null);
    },
    setFormSubmitSucceed: () => {
      setFormState(FormState.SUCCESS);
      setFormError(null);
    },
    setFormSubmitError: (err) => {
      setFormState(FormState.ERROR);
      setFormError(err);
    },
    resetFormState: () => {
      setFormState(FormState.INITIAL);
      setFormError(null);
    }
  };
}

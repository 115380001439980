import * as yup from 'yup';

export default yup.object().shape({
  financingReason: yup
    .string()
    .required('Bitte Finanzierungsgrund auswählen'),
  fixedInterestRate: yup
    .number()
    .required('Bitte Zinsbindung auswählen'),
  purchasePrice: yup
    .string()
    .matches(/^[0-9]+$/gi, 'Bitte nur eine Zahl ohne Punkt und Komma eingeben')
    .required('Bitte Kaufpreis angeben'),
  equity: yup
    .string()
    .matches(/^[0-9]+$/gi, 'Bitte nur eine Zahl ohne Punkt und Komma eingeben')
    .required('Bitte Eigenkapital angeben'),
});
